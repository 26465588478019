<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>CS No</th>
          <th>CS Date</th>
          <th>Requester</th>
          <th>Project</th>
          <th>Department</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>2132132</td>
          <td>12/3/22</td>
          <td>Zubayer Hossain</td>
          <td>Project</td>
          <td>Department</td>
          <td>Status</td>
          <td>
            <a href="#">Edit</a> | 
            <router-link :to="{
              name: 'cs-print', 
              params: route.params, 
              query: route.query
            }">Print</router-link>
          </td>
        </tr>
        </tbody>
      </table>
      <!-- <p v-if="!journals.length" class="text-center mt-5">No data</p> -->
    </div>
  </div>
</template>

<script setup>
import figureFormatter from '@/services/utils/figureFormatter'
import {useRoute} from 'vue-router'
const route = useRoute()
</script>
